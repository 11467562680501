function reverseString(str) {
    var newString = "";
    for (var i = str.length - 1; i >= 0; i--) {
        newString += str[i];
    }
    return newString;
}

function stringToHex(str) {
    var newString = "";
    for (var i = 0; i < str.length; i++) {
        newString += str.charCodeAt (i).toString (16).padStart (2, "0");
    }
    return newString;
}

function hexToString(str) {
    var newString = "";
    var c;
    str = str.toString();
    if ((str.length % 2) == 1) str = "0" + str;
    for (var i = 0; i < str.length; i += 2) {
        c = parseInt (str.substr (i, 2), 16);
        newString += String.fromCharCode(c);
    }
    return newString;
}

function reasignarCaracteresIncrementando(str) {
    var newString = "";
    var d = 64;
    var c;
    for (var i = 0; i < str.length; i++, d++) {
        c = (str.charCodeAt (i)+d) % 256;
        newString += String.fromCharCode(c);
    }
    return newString;
}

function reasignarCaracteresDecrementando(str) {
    var newString = "";
    var d = 64;
    var c;
    for (var i = 0; i < str.length; i++, d++) {
        c = (str.charCodeAt (i)-d) % 256;
        if (c < 0) c += 256;
        newString += String.fromCharCode(c);
    }
    return newString;
}

function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        var char = str.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

export function ofuscarCadena (str) {
    str = reasignarCaracteresIncrementando (str);
    str = stringToHex (str);
    str = reverseString (str);
    str = hexToString (str);
    str = btoa (str);
    return str;
}

export function desofuscarCadena (str) {
    str = atob (str);
    str = stringToHex (str);
    str = reverseString (str);
    str = hexToString (str);
    str = reasignarCaracteresDecrementando (str);
    return str;
}

export function uniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function uuid() {
    var u='',m='xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx',i=0,rb=Math.random()*0xffffffff|0;
    while(i++<36) {
        var c=m[i-1],r=rb&0xf,v=c=='x'?r:(r&0x3|0x8);
        u+=(c=='-'||c=='4')?c:v.toString(16);rb=i%8==0?Math.random()*0xffffffff|0:rb>>4
    }
    return u
}

export function deshabilitarAutocompletado (elementoDOM) {
    let elementos = elementoDOM.querySelectorAll ('input');
    elementos.forEach ((e) => {
        e.setAttribute ('autocomplete', 'off');
        e.setAttribute ('readonly', 'readonly');
        setTimeout(() => {
            e.removeAttribute('readonly');
        }, 750);
    });
}

export function cadenaAColor (cadena, saturation = 100, lightness = 75) {
    let hash = hashCode (cadena + cadena + cadena);
    let hue = (0 + hash) % 360;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

<template>
  <v-main class="fondo">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-card
          ref="tarjeta"
          width="400"
          class="py-6 px-10 text-center"
          elevation="0"
          style="margin-top: -20px; min-width: 300px"
        >
            <img :src="'assets/logo.png'" class="" style="max-height: 100px" />
          <div class="mt-8 text-h6" style="color: #31708f">{{$t('recuperarContrasenaTitulo')}}</div>
          <v-card-text>
          <v-text-field
            :label="$t('correo')"
            ref="inputCorreoElectronico"
            prepend-inner-icon="mdi-email"
            :rules="[rulesMail.required]"
            v-model="email"
            type="email"
            spellcheck="false"
          ></v-text-field>
          <v-btn
            class="mt-2"
            block
            large
            :loading="enviandoCorreo"
            color="primary"
            elevation="2"
            :disabled="!datosRellenos"
            @click="recuperarContrasena"
          >{{$t('recuperar')}}</v-btn>
          <div class="mt-5">
            <router-link to="/">
              <a>{{$t('inicioSesion')}}</a>
            </router-link>
          </div>
          </v-card-text>
        </v-card>
      </v-row>


      <v-dialog v-model="cuadroEmergente.mostrar" max-width="490">
        <v-card>
          <v-card-title class="headline">{{
            cuadroEmergente.titulo
          }}</v-card-title>
          <v-card-text>{{ cuadroEmergente.texto }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click="cuadroEmergente.mostrar = false"
              >{{ $t("aceptar") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import api from "@/api";
import {deshabilitarAutocompletado} from "@/util";
import { Preferences } from '@capacitor/preferences';
import i18n from "@/plugins/i18n";
export default {

  data: () => ({
    rules: {
      required: (value) => !!value || "Requerido",
    },
    rulesMail:{
      required:(value)=> !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || textoCorreoNoValido
    },
    email: "",
    enviandoCorreo:false,
    cuadroEmergente: {
      mostrar: false,
      titulo: "",
      texto: "",
    },
  }),
  methods: {
    completarEmail() {
      if (this.email != "" && !this.email.includes("@"))
        this.email = this.email + "@" + this.$parametros_configuracion.domcorelepordef;
    },
    recuperarContrasena() {
      this.enviandoCorreo = true;
      api.recuperarContrasena(this.email)
        .then((res) =>{
          if(res.data.respuesta == "Correo enviado"){
            this.cuadroEmergente.mostrar = true;
            this.cuadroEmergente.titulo = this.$t('enviamosCorreo');
            this.cuadroEmergente.texto = this.$t('enviarmosCorreoTexto');
            this.$router.push({path:'/'})
          }else if(res.data.respuesta == "Correo no valido"){
            this.cuadroEmergente.mostrar = true;
            this.cuadroEmergente.titulo = this.$t('correoNoValidoOlvido');
            this.cuadroEmergente.texto = this.$t('correoNoValidoOlvidoTexto');
          }
          else{
            this.cuadroEmergente.mostrar = true;
            this.cuadroEmergente.titulo = this.$t('errorEnvioCorreo');
            this.cuadroEmergente.texto = this.$t('errorEnvioCorreoTexto');
          }
        })
        .finally(() =>{
          this.enviandoCorreo = false;
        })
    },
  },
  computed: {
    textoCorreoNoValido(){
      return this.$t('correoNoValido');
    },
    datosRellenos() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    deshabilitarAutocompletado (this.$refs["tarjeta"].$el);
    this.$refs["inputCorreoElectronico"].focus();
  },
  created(){
    //Asignamos idioma por defecto
    Preferences.get({key:"idioma_caja_movil"})
      .then((res)=>{
        if(res['value']){

          i18n.locale = res['value']
        }else{
          Preferences.set({
            key: "idioma_caja_movil",
            value: "es",
          }).then(() => {
            i18n.locale = "es";
          });
        }
      })

    this.email = this.$estadoApp.emailOlvidado
  }
};
</script>

<style>
/* .fondo{
    background-image: url('/assets/fondo.jpg');
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
} */
</style>